function scrollToHashWithTimeout(hash: string, timeout = 500): void {
    const element: HTMLElement = document.querySelector(hash);

    if (element instanceof HTMLElement) {
        setTimeout(() => {
            element.scrollIntoView({ behavior: 'smooth' });
        }, timeout);
    }
}

window.addEventListener('load', () => {
    const hash = window.location.hash;

    if (hash) {
        scrollToHashWithTimeout(hash);
    }
});
